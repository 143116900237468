import React from 'react'

import * as S from './styles'
import marcasJSON from './data.json'
import samsung from './assets/samsung.svg'
import apple from './assets/apple.svg'
import adidas from './assets/adidas.svg'
import dell from './assets/dell.svg'
import electrolux from './assets/electrolux.svg'
import lg from './assets/lg.svg'
import mondial from './assets/mondial.svg'
import nike from './assets/nike.svg'

type Props = {
  alt: string;
  img: string;
}

type Brands = 'samsung' | 'apple' | 'dell' | 'electrolux' | 'adidas' | 'nike' | 'mondial' | 'lg'

const TheBrands = () => {
  const mapImages = {
    samsung,
    apple,
    adidas,
    dell,
    electrolux,
    lg,
    mondial,
    nike,
  }

  return (
    <S.Section>
      <div className='container text-center'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <h2 className='text-white fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50'>As marcas mais desejadas da Black Friday 2023</h2>
          </div>
          <div className='col-12 col-md-10 col-lg-8 col-xl-12 d-flex justify-content-between flex-wrap my-3'>
            {
              marcasJSON.map((item: Props) => (
                <div key={item.img}>
                  <S.Card>
                    <img src={mapImages[item.img as Brands] } alt={item.alt} />
                  </S.Card>
                </div>
              ))
            }
          </div>
          <div className='col-12'>
            <p className='text-white fs-16 lh-22'>São mais de 400 lojas para você comprar com cashback no Inter Shop!</p>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default TheBrands
