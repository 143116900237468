import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const Section = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;
  background: ${grayscale[500]};
  margin-top: -1px;

  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  a,
  button {
    @media ${device.desktopLG} {
      max-width: 100%;
    }
  }

  img {
    @media ${device.tablet} {
      max-width: 100%;
    }
    @media ${device.desktopLG} {
      max-width: none;
    }
  }

  img {
    max-width: 556px;
  }
`

export const Benefits = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  & + & {
    margin-top: 18px;
  }
`

export const ConditionCard = styled.div`
  svg {
    width: 16px;
    height: 16px;

    @media ${device.tablet} {
      width: 24px;
      height: 24px;
    }
  }
`
