import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

type Props = {
  collapsed: boolean;
}

export const Wrapper = styled.div`
  background-color: ${grayscale[500]};
  padding-bottom: 40px;
  overflow: hidden;
  
  @media ${device.tablet} {
    padding-bottom: 40px;
  }

  @media ${device.desktopLG} {
    padding-bottom: 80px;
  }

  @media ${device.desktopXL} {
    padding-bottom: 96px;
  }

  h3 {
    color: white;
    font-family: 'Inter';
    font-size: 16px;
    line-height: normal;
    margin-top: 40px;
    font-weight: 600;
    @media ${device.tablet} {
      font-size: 18px;
    }
  }

  p {
    color: white;
    font-size: 16px;
    @media ${device.tablet} {
      font-size: 18px;
  }

  a {
    font-weight: 600;
  }
}
`
export const CollapsableContent = styled.div<Props>`
  height: ${(props: Props) => props.collapsed ? '300px' : 'fit-content'};
  overflow: hidden;
`
