import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { CashbackStyle, ImageCashback, ImageOrangeFridayBg, Section } from './styles'

function Hero () {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  const CashbackText = () =>
    <CashbackStyle>
      <p className='mb-0 fs-12 lh-15 fs-md-12 text-white'>Até</p>
      <p className='mb-0 fs-48 lh-60 fs-md-40 fs-lg-50 text-white'><span className='text-orange--extra'>80%</span> OFF</p>
      <div className='d-flex'>
        <div>
          <p className='mb-0 fs-48 fs-lg-50 text-white'>+</p>
          <p className='mb-0 fs-12 lh-15 fs-md-12 text-white'>Até</p>
        </div>
        <p className='mb-0 fs-48 lh-60 fs-md-40 fs-lg-50 text-orange--extra'>{' '}35%{' '}</p>
        <div>
          <p className='mb-0 fs-18 lh-17 fs-md-16 fs-lg-19 text-white'>CASH</p>
          <p className='mb-0 fs-18 lh-17 fs-md-16 fs-lg-19 text-white'>BACK</p>
        </div>
      </div>
    </CashbackStyle>

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center justify-content-between' style={{ position: 'relative' }}>
          {
            width >= 768 && (
              <>
                <ImageCashback role='img' aria-label='Homem feliz, pulando, enquanto mostra a tela do celular ao aproveitar as melhores ofertas da Black Friday no Super App do Inter' />
                <ImageOrangeFridayBg />
              </>
            )
          }
          <div className='col-12 col-md-6 offset-md-6 px-0'>
            <div style={{ minHeight: 96 }}>
              <h1 className='font-sora fw-600 fs-24 lh-32 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white mb-md-3 mb-lg-4 pb-lg-2'>
                <span className='d-lg-block'>Se joga na</span>
                <span className='text-orange--extra'>Orange Friday</span>
                , <span className='d-lg-block'>a Black Friday do Inter</span>
              </h1>
            </div>
            <CashbackText />
            <div className='pt-4 mt-lg-4 pt-lg-2'>
              <a
                href='https://intergo.app/045c6064'
                target='blank'
                className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-700 text-none'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Se joga na Orange Friday, a Black Friday do Inter',
                    element_action: 'click button',
                    element_name: 'Comprar agora',
                    redirect_url: 'https://intergo.app/045c6064',
                  })
                }}
              >
                Comprar agora
              </a>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
