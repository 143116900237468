export const images = {
  bannerBetterPaymentConditions: {
    link: 'https://central-imagens.bancointer.com.br/images-without-small-versions/banner-better-payment-conditions/image.png',
    alt: 'Celular mostrando a tela de pagamentos no Super App do Inter ao lado de um cartão de crédito platinum simulando uma compra na Black Friday.',
  },
  bannerOrangeBox: {
    link: 'https://central-imagens.bancointer.com.br/images-without-small-versions/banner-orange-box-is-here/image.png',
    alt: 'Caixa laranja escrito orange box com produtos saindo dela, como notebook, alexa, celular e robô aspirador na black friday',
  },
}
