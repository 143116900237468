import React from 'react'
import linksJSON from './data.json'
import * as S from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type Props = {
  text: string;
  link: string;
}

function WarmUpBlackFriday () {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.Wrapper>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center mb-3'>
            <h2 className='fs-40 font-sora text-white'>Esquenta Black Friday</h2>
            <p className='fs-18 text-white'>Ansioso pro <span className='fw-600'>dia da Black Friday</span>? Aqui tem ofertas quentes pra você, vem!</p>
          </div>
          <div className='col-12 d-flex flex-wrap justify-content-center'>
            {
            linksJSON.map((item: Props) => (
              <div key={item.text}>
                <a
                  href={item.link}
                  target='blank'
                  className='text-white fs-14'
                  role='button'
                  aria-label={item.text}
                  onClick={() => sendDatalayerEvent({
                    section: 'dobra_09',
                    element_action: 'click button',
                    element_name: item.text,
                    section_name: 'Esquenta Black Friday',
                    redirect_url: item.link,
                   })}
                >
                  {item.text}
                </a>
              </div>
            ))
          }
          </div>
        </div>
      </div>
    </S.Wrapper>
  )
}

export default WarmUpBlackFriday
