import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import * as S from './styles'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import produtosJSON from './data.json'
import { cardImages } from '../../assets/images/black-friday-cards/_index'
type Cards = {
  title: string;
  link: string;
  img: string;
  description: string;
  alt: string;
  redirect: string;
}

type CardsContent = {
  id: string;
  items: Cards[];
}

const EverythingForYouToEnjoy = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <S.Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-white mb-4 text-center'>
              <span className='d-md-block'>Tudo pra você aproveitar a</span> Black Friday 2023 em um só lugar
            </h2>
          </div>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 80 }}
              md={{ items: 3, partialVisibilityGutter: 10 }}
              lg={{ items: 4, partialVisibilityGutter: 10 }}
              xl={{ items: 5 }}
              autoPlay
              infinite
              notShowArrows
              notShowDots
            >
              {
                produtosJSON.map((CardsContent) => (
                  <div key={CardsContent.id} className='mx-1'>
                    {CardsContent.items.map((item: Cards) => (
                      <S.Card key={item.title}>
                        <S.CardImg url={cardImages[item.img]} $interempresas={item.title === 'Inter Empresas'} role='img' aria-label={item.alt} />
                        <S.CardText className='fs-14 lh-17 text-white fw-700' dangerouslySetInnerHTML={{ __html: item.title }} />
                        <S.CardBottom>
                          <div className='mt-4 text-center px-3'>
                            <p className='text-grayscale--500 fs-16 lh-20 mb-0 font-sora mt-2'>{item.description}</p>
                          </div>
                          <div className='px-3 py-3'>
                            <a
                              href={item.link} target='__blank'
                              rel='noreferrer'
                              className='btn btn-orange--extra btn--lg rounded-2 text-none text-white '
                              onClick={() => {
                                sendDatalayerEvent({
                                  section: 'dobra_08',
                                  section_name: 'Tudo pra você aproveitar a Black Friday 2023 em um só lugar',
                                  element_action: 'click banner + ' + item.title,
                                  element_name: 'Aproveitar',
                                  redirect_url: item.redirect,
                                })
                              }}
                            >Aproveitar
                            </a>
                          </div>
                        </S.CardBottom>
                      </S.Card>
                  ))}
                  </div>
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default EverythingForYouToEnjoy
