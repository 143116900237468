import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'

export const Section = styled.section`
  padding: 40px 0;
  background-color: ${grayscale[500]};
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/bg-dobra-blog/image.webp');
  background-size: cover;
  margin-top: -1px;
  
  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  a,
  button {
    @media ${device.desktopLG} {
      max-width: 100%;
    }
  }

  img {
    @media ${device.tablet} {
      max-width: 100%;
    }
    @media ${device.desktopLG} {
      max-width: none;
    }
  }

  .fw-600 {
    font-weight: 600;
  }
`

export const IphoneTextCard = styled.div`
  border-radius: 10px;
  border: 3px solid ${orange.base};
  color: ${orange.base};
  background-color: ${orange[100]};
  padding: 10px 20px;
  width: fit-content;
`

export const AlertBox = styled.div`
  background-color: ${orange[100]};
  border-radius: 12px;
  box-shadow: 0px 0px 15px 0 ${orange[100]};
  display: flex;
  align-items: center;
  margin-right: 25px;
  padding: 15px;

  @media ${device.desktopLG} {
    padding: 25px;
  }

`

export const AlertGif = styled.img`
  position: absolute;
  right: -55px;
  top: calc(50% - 20px);
  width: 100px;
  height: 40px;

  @media ${device.tablet} {
    right: -55px;
    width: 150px;
    height: 55px;
    top: calc(50% - 27px);
  }
`
