import React, { useState } from 'react'
import * as S from './style'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

function Duvidas () {
  const [ collapsed, setCollapse ] = useState(true)
  const [ sendDatalayerEvent ] = useDataLayer()

  function handleClick (title: string, link: string) {
    sendDatalayerEvent({
      section: 'dobra_11',
      element_action: 'click button',
      element_name: title,
      section_name: 'Dúvidas sobre a Black Friday 2023? A gente simplifica',
      redirect_url: link,
     })
  }

  return (
    <S.Wrapper>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-white font-sora mb-4' style={{ fontWeight: 600 }}>
              Dúvidas sobre a Black Friday 2023? A gente simplifica
            </h2>
          </div>
          <div className='col-12'>
            <S.CollapsableContent collapsed={collapsed}>
              <h3>O que é Black Friday?</h3>
              <p>Black Friday é um tradicional dia de promoções e ofertas que surgiu nos Estados Unidos e se popularizou em todo mundo.</p>
              <p>Esta é a melhor época do ano para fazer compras, pois você aproveita uma série de descontos em produtos como eletrodomésticos, smartphones, games, eletroportáteis, móveis, vestuário, cosméticos e presentes em diversas lojas do Brasil.</p>
              <h3>Quando é a Black Friday 2023?</h3>
              <p>
                <span className='fw-600'>A Black Friday será no dia 24 de novembro. {' '}</span>
                Isso porque ela acontece sempre na 4º sexta-feira do mês de novembro, após o Dia de Ação de Graças nos Estados Unidos, e marca a abertura da temporada de
                <span className='fw-600'>{' '}compras de Natal.</span>
              </p>
              <p>Mas apesar de ter uma data específica para a Black Friday, muitas marcas e lojas antecipam os <span className='fw-600'>melhores descontos {' '}</span>
                e ofertas promovendo vários dias de condições especiais. A data se encerra com a realização da <span className='fw-600'>Cyber Monday</span>, 'Segunda-Feira Cibernética' em português, um evento de descontos exclusivamente das lojas online.
              </p>
              <h3>Como economizar na Black Friday?</h3>
              <p>Pra aproveitar as <span className='fw-600'>melhores ofertas da Black Friday 2023</span>, não deixe de seguir algumas dicas:</p>
              <p className='mb-0'>1. Compare preços;</p>
              <p className='mb-0'>2. Leia sobre os produtos que deseja <span className='fw-600'>comprar na Black Friday;</span></p>
              <p className='mb-0'>3. Confira o prazo de entrega, condições de parcelamento e descontos da Black Friday 2023;</p>
              <p className='mb-0'>4. Certifique-se de comprar em <span className='fw-600'>sites confiáveis</span>;</p>
              <p className='mb-0'>5. Dê preferência para as lojas que ofereçam, além do desconto, <a href='https://blog.inter.co/o-que-e-cashback' onClick={() => handleClick('cashback', 'https://blog.inter.co/o-que-e-cashback')}>cashback</a> nas compras;</p>
              <p className='mb-0'>6. Aproveite <a href='https://shopping.inter.co/cupons' onClick={() => handleClick('cupons de desconto', 'https://shopping.inter.co/cupons')}>cupons de desconto</a> e muito cashback, no Inter Shop. Nele, você compra com preço de Black Friday e aproveita condições imperdíveis nas lojas e marcas que mais gosta: smartphones, celulares, notebooks, TVs, eletroportáteis, móveis e muito mais.</p>
              <h3>Ofertas Black Friday: onde encontrar as melhores?</h3>
              <p>Pra sair na frente no <a href='https://blog.inter.co/dia-da-black-friday' onClick={() => handleClick('dia da Black Friday', 'https://blog.inter.co/dia-da-black-friday')}>dia da Black Friday</a> a dica é cadastrar na Lista VIP da Orange Friday. Assim, você vai receber, em primeira mão, ofertas relâmpago, cupons de desconto exclusivos, melhores preços e condições do mercado nos seus produtos favoritos. Então, se você ficou esperando o ano inteiro pra
                <span className='fw-600'>{' '}comprar iPhone na Black Friday{' '}</span>
                , por exemplo, garanta o seu com o melhor preço! Pra isso, você também pode aproveitar o Alerta de Preço Baixo no Inter Shop. Você sugere quanto quer pagar e a gente te avisa quando encontrarmos o <span className='fw-600'>melhor preço</span> do mercado. Demais né?
              </p>
              <h3>Celular Black Friday</h3>
              <p>A Black Friday 2023 também promete muitas <span className='fw-600'>promoções de notebook</span> e PC gamer. Seja para estudar, trabalhar ou jogar, novembro é a melhor época do ano pra <span className='fw-600'>comprar notebook</span> e se preparar pro próximo ano. É também uma ótima oportunidade pra fazer suas compras de Natal.</p>
              <h3>Black Friday roupas</h3>
              <p>Tem muita gente buscando dar aquela renovada no guarda-roupas nesta Black. Isso porque, com a chegada do período de festas de fim de ano, a gente sempre quer investir num look novo e especial pra curtir com a gente ama, não é mesmo?! A dica é se ficar ligado nas <span className='fw-600'>ofertas da Orange Friday</span>, pois no Inter Shop tem centenas de <a href='https://shopping.inter.co/lojas' onClick={() => handleClick('lojas', 'https://shopping.inter.co/lojas')}>lojas</a> e marcas pra você aproveitar (só as melhores!)</p>
            </S.CollapsableContent>
          </div>
          <div className='col-12 text-center mt-4'>
            {
              !collapsed ? (
                <button onClick={() => setCollapse(!collapsed)} style={{ background: 'none' }} className='text-orange--extra fw-600'>
                  Mostrar menos
                  <OrangeDsIcon icon='chevron-up' size='SM' color='#FF7A00' />
                </button>
              ) : (
                <button onClick={() => setCollapse(!collapsed)} style={{ background: 'none' }} className='text-orange--extra fw-600'>
                  Mostrar mais
                  <OrangeDsIcon icon='chevron-down' size='SM' color='#FF7A00' />
                </button>
              )
            }
          </div>
        </div>
      </div>
    </S.Wrapper>
  )
}

export default Duvidas
