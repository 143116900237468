import styled from 'styled-components'
import { orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-color: ${orange.extra};
  padding: 40px 0;
  margin-top: -1px;
  @media ${device.tablet} {
    padding: 40px 0;
  }

  @media ${device.desktopLG} {
    padding-bottom: 80px;
  }

  @media ${device.desktopXL} {
    padding-bottom: 96px;
  }
  a {
    &:hover {
      color: ${orange.extra} ;
    }
  }
`

export const Card = styled.div`
  width: 130px;
  border-radius: 12px;
  background-color: white;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`
