import React from 'react'

import * as S from './styles'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import Hamburguer from 'inter-frontend-svgs/lib/orangeds/MD/hamburguer'

function OnlyVIP () {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.Section>
      <S.VipBg />
      <div className='container text-center'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 text-white font-sora' style={{ fontWeight: 600 }}>Vantagens turbinadas pra quem é VIP</h2>
          </div>
          <div className='col-12 col-xl-11 d-flex flex-md-row flex-column align-items-center justify-content-md-between mt-5 py-4'>
            <S.Card className='ml-md-5 orange-box-surpresa mt-5'>
              <S.MiniCard className='orange-box-surpresa'>
                <OrangeDsIcon icon='money-down' color='#FF7A00' size='LG' />
              </S.MiniCard>
              <h3 className='fs-18 lh-22 fs-md-14 lh-md-18 fs-lg-16 lh-lg-20 fs-xl-24 lh-xl-30 text-grayscale--500 font-sora mb-1' style={{ fontWeight: 600 }}>Orange Box <span className='d-lg-block'>surpresa</span></h3>
              <p className='fs-12 lh-15 fs-lg-14 lh-lg-17 fs-xl-16 lh-xl-20 text-grayscale--500 mb-1'>produtos desejados com até 50% OFF</p>
            </S.Card>
            <S.Card className='cupons-de-desconto'>
              <S.MiniCard className='cupons-de-desconto'>
                <OrangeDsIcon icon='coupon' color='#FF7A00' size='LG' />
              </S.MiniCard>
              <h3 className='fs-18 lh-22 fs-md-14 lh-md-18 fs-lg-16 lh-lg-20 fs-xl-24 lh-xl-30 text-grayscale--500 font-sora mb-1' style={{ fontWeight: 600 }}>Cupons de <span className='d-lg-block'>desconto</span></h3>
              <p className='fs-12 lh-15 fs-lg-14 lh-lg-17 fs-xl-16 lh-xl-20 text-grayscale--500 mb-1'>exclusivos e enquanto durarem os estoques</p>
            </S.Card>
          </div>
          <div className='col-12 d-flex flex-md-row flex-column align-items-center justify-content-md-between mt-md-5'>
            <S.Card className='pagamento-facilitado'>
              <S.MiniCard className='pagamento-facilitado'>
                <OrangeDsIcon icon='coupon' color='#FF7A00' size='LG' />
              </S.MiniCard>
              <h3 className='fs-18 lh-22 fs-md-14 lh-md-18 fs-lg-16 lh-lg-20 fs-xl-24 lh-xl-30 text-grayscale--500 font-sora mb-1' style={{ fontWeight: 600 }}>Pagamento facilitado*</h3>
              <p className='fs-12 lh-15 fs-lg-14 lh-lg-17 fs-xl-16 lh-xl-20 text-grayscale--500 mb-1'>mais limite de crédito e parcelamento em até 24x</p>
              <p className='fs-10 lh-18 text-grayscale--500 mb-1'>*sujeito a análise</p>
            </S.Card>
            <S.Card className='duo-gourmet'>
              <S.MiniCard className='duo-gourmet'>
                <Hamburguer color='#FF7A00' width={45} height={45} />
              </S.MiniCard>
              <h3 className='fs-18 lh-22 fs-md-14 lh-md-18 fs-lg-16 lh-lg-20 fs-xl-24 lh-xl-30 text-grayscale--500 font-sora mb-1' style={{ fontWeight: 600 }}>Duo <span className='d-xl-block'>Gourmet</span></h3>
              <p className='fs-12 lh-15 fs-lg-14 lh-lg-17 fs-xl-16 lh-xl-20 text-grayscale--500 mb-1'>cartão Black + até R$60 OFF no plano anual</p>
            </S.Card>
            <S.Card className='mr-md-5 oferta-relampago'>
              <S.MiniCard className='oferta-relampago'>
                <OrangeDsIcon icon='cart-add' color='#FF7A00' size='LG' />
              </S.MiniCard>
              <h3 className='fs-18 lh-22 fs-md-14 lh-md-18 fs-lg-16 lh-lg-20 fs-xl-24 lh-xl-30 text-grayscale--500 font-sora mb-1' style={{ fontWeight: 600 }}>Ofertas <span className='d-md-block'>relâmpago</span></h3>
              <p className='fs-12 lh-15 fs-lg-14 lh-lg-17 fs-xl-16 lh-xl-20 text-grayscale--500 mb-1'>receba em primeira mão os melhores descontos</p>
            </S.Card>
          </div>
          <div className='col-12 mt-3'>
            <a
              href='https://intergo.app/045c6064'
              target='blank'
              className='btn btn--lg text-none text-white bg-orange rounded-2 mb-3 button-quero-ser-vip'
              onClick={() => sendDatalayerEvent({
                section: 'dobra_02',
                element_action: 'click button',
                element_name: 'Aproveitar a Orange Friday',
                section_name: 'Só quem é VIP tem',
               })}
            >
              Aproveitar a Orange Friday
            </a>
            <p className='fs-12 lh-15 fs-md-14 lh-md-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-white lh-18'>
              O cadastro na Lista VIP da Orange Friday encerrou em 22/11. Se você fez a sua inscrição, aproveite essas super vantagens na Black Friday do Inter!
            </p>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default OnlyVIP
