import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'
import HeroMD from './assets/black-friday-inter-hero.png'

export const Section = styled.section`
  background-color: ${grayscale[500]};

  @media ${device.tablet} {
    height: 550px;
  }

  @media ${device.desktopLG} {
    height: 677px;
  }

  @media ${device.desktopXL} {
    height: 739px;
  }

  h1 {
    margin-top: 96px;

    @media ${device.desktopXL} {
      margin-top: 125px;
    }
  }
`

export const CashbackStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-family: 'Sora';
    font-weight: 600;
    line-height: 1;
  }

  @media ${device.tablet} {
    height: fit-content;
  }
`

export const ImageCashback = styled.div`
  width: 200px;
  height: 300px;
  margin-top: 10px;
  position: absolute;
  left: -30px;
  z-index: 1;

  @media ${device.tablet} {
    background-image: url(${HeroMD});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    height: 685px;
    width: 270px;
    left: 0px;
    top: -142px;
  }

  @media ${device.desktopLG} {
    height: 607px;
    width: 350px;
    top: 43px;
  }

  @media ${device.desktopXL} {
    height: 709px;
    width: 400px;
  }
`
export const ImageOrangeFridayBg = styled.div`
  width: 100%;
  height: 550px;
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra-01-background/image.webp');
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  left: -24px;

  @media ${device.desktopLG} {
    height: 750px;
    left: -32px;
    top: -33px;
  }

  @media ${device.desktopXL} {
    left: -142px;
    height: 128%;
    width: 1332px;
    top: 38px;
  }

  @media ${device.desktopXXXL} {
    left: -239px;
  }
`
