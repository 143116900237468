import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${grayscale[500]};

  padding: 40px 0;
  
  @media ${device.tablet} {
    padding: 40px 0;

  }

  @media ${device.desktopLG} {
    padding: 80px 0;
  }

  @media ${device.desktopXL} {
    padding: 96px 0;
  }

  a {
    cursor: pointer;
    margin: 0px 15px;

    &:hover {
      color: ${orange.extra} !important;
    }
  }
`
