import styled from 'styled-components'
import { grayscale, green, orange, red } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-color: ${orange.extra};
  overflow: hidden;

  padding: 40px 0;
  @media ${device.tablet} {
    padding: 40px 0;

  }

  @media ${device.desktopLG} {
    padding: 80px 0;
  }

  @media ${device.desktopXL} {
    padding: 96px 0;
  }
`

export const Card = styled.div`
  width: 95%;
  margin-right: 15px;
  border-radius: 12px;
  background-color: ${grayscale[500]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const CardImg = styled.div`
  width: 100%;
  background-color: white;
  padding: 15px;
`

export const CardText = styled.h3`
  font-family: 'Inter' !important;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
`

export const CardLabels = styled.div`
  margin-top: -15px;
  margin-left: 16px;

  .discount {
    background-color: ${green[400]};
    margin-bottom: 5px !important;
    font-weight: 700;
    padding: 0 8px;
    border-radius: 18px;  
    width: fit-content;
  }

  .stock {
    background-color: ${red.base};
    padding: 0 8px;
    border-radius: 18px;
    width: fit-content;
    font-weight: 700;
  }
`

export const CardPrice = styled.span`
  opacity: 0.8;
  filter: blur(2.5px);
`
