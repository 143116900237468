import styled from 'styled-components'
import { grayscale, green, orange, red } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

type CardImgType = {
  url: string;
  $interempresas: boolean;
}

export const Section = styled.section`
  background-color: ${orange.extra};
  padding: 40px 0;
  @media ${device.tablet} {
    padding: 40px 0;

  }

  @media ${device.desktopLG} {
    padding: 80px 0;
  }

  @media ${device.desktopXL} {
    padding: 96px 0;
  }
`

export const Card = styled.div`
  border-radius: 12px;
  background-color: ${orange[100]};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-right: 32px !important;

  &:first-child {
    margin-bottom: 40px;
  }

  height: 446px;

  @media ${device.desktopXL} {
    margin: 0 13px !important;
    margin-bottom: 40px !important;
    max-width: 192px;
  }
`

export const CardImg = styled.div<CardImgType>`
  background-image: url(${(props: CardImgType) => props.url});
  background-repeat: no-repeat;
  background-color: ${orange[200]};
  background-size: ${(props: CardImgType) => props.$interempresas ? 'contain' : 'cover'};
  background-position: top;
  width: 100%;
  height: 360px;
  margin-bottom: -25px;
  border-radius: 12px;

  @media ${device.tablet} {
    background-size: 'cover';
  }
`

export const CardText = styled.h3`
  font-family: 'Inter' !important;
  text-align: center;
  padding: 10px 25px;
  background-color: ${grayscale[500]};
  width: fit-content;
  border-radius: 32px;
  margin-bottom: -25px;
  z-index: 1;
`

export const CardLabels = styled.div`
  margin-top: -15px;
  margin-left: 16px;

  .discount {
    background-color: ${green[400]};
    margin-bottom: 5px !important;
    font-weight: 700;
    padding: 0 8px;
    border-radius: 18px;  
    width: fit-content;
  }

  .stock {
    background-color: ${red.base};
    padding: 0 8px;
    border-radius: 18px;
    width: fit-content;
    font-weight: 700;
  }
`

export const CardBottom = styled.div`
  background-color: white;
  border-radius: 12px;
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
