import React from 'react'

import FaqComponent from 'src/components/Faq'
import { grayscale, white } from 'src/styles/colors'

import { Section } from './styles'

type answerDataProps = {
  question: string;
  answer: string;
};

type FaqSectionPropsType = {
  faqData: answerDataProps[];
};

export const FAQ = ({ faqData }: FaqSectionPropsType) => {
  return (
    <Section id='faq' className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h3 className='fs-20 lh-25 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fw-600 text-grayscale--100'>
              Perguntas frequentes
            </h3>
          </div>
          <div className='col-12'>
            <FaqComponent
              id='inter-black'
              className='summary-content px-0 text-white'
              columns={{ sm: 1, lg: 1, xl: 1 }}
              answerData={faqData}
              questionColor='text-white'
              answersColor='text-white'
              borderColor={white}
              searchBg={grayscale[100]}
              placeholderColor={grayscale[300]}
            />
          </div>
        </div>
      </div>
    </Section>
  )
}
