import React from 'react'

import * as S from './styles'

import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { ImgWebp } from 'src/styles/imgWebp'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import AlertGif from './assets/black-friday-gif-criar-alerta.gif'

const LowPriceAlert = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_07',
    section_name: 'Alerta de preço baixo? Se joga na Orange Friday!',
    element_action: 'click button',
    element_name: 'Criar alerta',
    redirect_url: 'https://shopping.inter.co/?utm_source=marketplace_intershop_blackfriday_lp&utm_medium=intershop&utm_campaign=blackfriday_lp',
  }

  return (
    <S.Section>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 text-center'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white'>
              Alerta de preço baixo?
              <div>Se joga na <span className='text-orange--extra'>Orange Friday!</span></div>
            </h2>
            <p className='fw-400 fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-grayscale--100 mb-4 pb-2 mr-n3'>
              No Inter Shop você cria <span className='fw-600'>Alerta de Preço</span> e aproveita, do seu jeito, as <span className='fw-600'> melhores ofertas da Black Friday</span>. Experimente agora!
            </p>
          </div>

          <div className='col-5 col-md-3 d-flex flex-column align-items-end'>
            <p className='text-white font-sora fs-24 fs-lg-28 fs-xl-48 mb-0 fw-600'>Iphone 14</p>
            <p className='text-white font-sora fs-12 fs-lg-14 fs-xl-22 fw-600' style={{ textDecoration: 'line-through' }}>de: R$ 5.299</p>
            <S.IphoneTextCard>
              <p className='fs-14 fs-lg-18 fs-xl-28 font-sora text-orange--extra fw-600 mb-0'>por: ???</p>
            </S.IphoneTextCard>
          </div>

          <div className='col-5 col-md-3' style={{ position: 'relative' }}>
            <ImgWebp
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra3-mobile-black-friday/image.webp'
              breakPointsSize={{ sm: '100%' }}
            />
            <a
              href='https://intergo.app/c3d207d4'
              target='__blank'
              rel='noreferrer'
              role='button'
              aria-label='Criar Alerta'
              onClick={() => sendDatalayerEvent(dataLayer)}
            >
              <S.AlertGif src={AlertGif} width={100} height={40} />
            </a>
          </div>

          <div className='col-2 col-md-1' />

          <div className='col-12 col-md-5 mt-4 mt-md-0'>
            <div className='d-flex mb-5'>
              <S.AlertBox>
                <OrangeDsIcon icon='notification' size='XL' color='#FF7A00' className='d-none d-xl-block' />
                <OrangeDsIcon icon='notification' size='LG' color='#FF7A00' className='d-block d-xl-none' />
              </S.AlertBox>
              <p className='fs-14 fs-lg-18 fs-xl-24 lh-lg-28 lh-20 lh-xl-30 font-sora text-white' style={{ marginTop: 'auto' }}>
                <span className='fw-600'>Escolha</span> um produto e crie um Alerta
              </p>
            </div>
            <div className='d-flex mb-5'>
              <S.AlertBox>
                <OrangeDsIcon icon='dollar' size='XL' color='#FF7A00' className='d-none d-xl-block' />
                <OrangeDsIcon icon='dollar' size='LG' color='#FF7A00' className='d-block d-xl-none' />
              </S.AlertBox>
              <p className='fs-14 fs-lg-18 fs-xl-24 lh-lg-28 lh-20 lh-xl-30 font-sora text-white' style={{ marginTop: 'auto' }}>
                <span className='fw-600'>Sugira</span> quanto quer pagar
              </p>
            </div>
            <div className='d-flex mb-5'>
              <S.AlertBox>
                <OrangeDsIcon icon='search' size='XL' color='#FF7A00' className='d-none d-xl-block' />
                <OrangeDsIcon icon='search' size='LG' color='#FF7A00' className='d-block d-xl-none' />
              </S.AlertBox>
              <p className='fs-14 fs-lg-18 fs-xl-24 lh-lg-28 lh-20 lh-xl-30 font-sora text-white' style={{ marginTop: 'auto' }}>
                <span className='fw-600'>Buscamos</span> o melhor preço pra você
              </p>
            </div>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default LowPriceAlert
