import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import { Wrapper } from './styles'

import pageContext from './pageContext.json'
import FlashSale from './sections/TemOfertaRelampago/_index'
import { FAQ } from './sections/FAQ/_index'
import Hero from './sections/Hero/_index'
import TheBrands from './sections/AsMarcas/_index'
import BestPaymentConditions from './sections/MelhoresCondicoesDePagamento/_index'
import OrangeBox from './sections/ASuaOrangeBoxEstaAqui/_index'
import LowPriceAlert from './sections/AlertaPreco/_index'
import EverythingForYouToEnjoy from './sections/TudoParaVoceAproveitar/_index'
import WarmUpBlackFriday from './sections/EsquentaBlackFriday/_index'
import OnlyVIP from './sections/VIP/_index'
import Blog from './sections/Blog/_index'
import useWidth from 'src/hooks/window/useWidth'
import Duvidas from './sections/Duvidas/_index'

const BlackFriday = () => {
  const width = useWidth()

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <FlashSale />
        <TheBrands />
        <LowPriceAlert />
        <OrangeBox />
        <EverythingForYouToEnjoy />
        {width >= 768 ? (<WarmUpBlackFriday />) : ''}
        <OnlyVIP />
        <BestPaymentConditions />
        <Blog />
        <Duvidas />
        <FAQ faqData={pageContext.structuredData.faq} />
      </Layout>
    </Wrapper>
  )
}
export default BlackFriday
