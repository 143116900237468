interface ICardImages {
  [index: string]: string;
}

export const cardImages: ICardImages = {
  giftCardsCardImage: 'https://central-imagens.bancointer.com.br/images-without-small-versions/gift-card-img/image.png',
  shellBoxCardImage: 'https://central-imagens.bancointer.com.br/images-without-small-versions/shell-box-card/image.png',
  duoGourmetCardImage: 'https://central-imagens.bancointer.com.br/images-without-small-versions/duo-goumert-card/image.png',
  RecargaDeCelularCardImage: 'https://central-imagens.bancointer.com.br/images-without-small-versions/recarga-de-celular/image.png',
  ConsorciosCardImage: 'https://central-imagens.bancointer.com.br/images-without-small-versions/consorcio-card/image.png',
  PassagensEHospedagensCardImage: 'https://central-imagens.bancointer.com.br/images-without-small-versions/passagens-e-hospedagens-card/image.png',
  ShopEmpresasCardImage: 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-empresas-card/image.png',
  CreditoImobiliarioCardImage: 'https://central-imagens.bancointer.com.br/images-without-small-versions/black-friday-mulher/image.webp',
  InvestimentosCardImage: 'https://central-imagens.bancointer.com.br/images-without-small-versions/investimentos-card/image.png',
  PlanoDeCelularCardImage: 'https://central-imagens.bancointer.com.br/images-without-small-versions/plano-de-celular-card/image.png',
}
