import React from 'react'

import * as S from './styles'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { images } from '../../assets/images/_index'

import { ImgWebp } from 'src/styles/imgWebp'

const OrangeBox = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.Section>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 order-2 order-md-1 px-0'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white mb-4 mb-md-3'>
              A sua <span className='text-orange--extra'>Orange Box</span> está aqui! <span className='d-md-block'>Acompanhe as Lives do Inter Shop e concorra a uma oferta secreta</span>
            </h2>
            <p className='fw-400 fs-14 lh-17 fs-lg-18 lh-lg-22 fs-xl-18 lh-xl-22 text-white mb-4 pb-2'>
              Entre na disputa pela caixa surpresa mais famosa do Brasil. Você pode  receber algo muuito mais incrível do que esperava!
            </p>
            <div className='pt-3'>
              <a
                href='https://intergo.app/045c6064'
                className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-700 text-none'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_06',
                    section_name: 'A sua Orange Box está aqui! Seja VIP e concorra a uma oferta secreta',
                    element_action: 'click button',
                    element_name: 'Acessar o Inter Shop',
                    redirect_url: 'https://intergo.app/045c6064',
                  })
                }}
              >
                Acessar o Inter Shop
              </a>
            </div>
          </div>
          <div className='col-12 col-md-6 col-xl-5 offset-xl-1 mb-5 md-md-0 order-1 order-md-2'>
            <ImgWebp src={images.bannerOrangeBox.link} alt={images.bannerOrangeBox.alt} breakPointsSize={{ sm: '320px', md: '360px', lg: '440px', xl: '456px' }} />
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default OrangeBox
