import React from 'react'
import * as S from './style'
import data from './assets/data.json'
import image01 from './assets/black-friday-blog-01.png'
import image02 from './assets/black-friday-blog-02.png'
import image03 from './assets/black-friday-blog-03.png'
import image04 from './assets/black-friday-blog-04.png'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

function Blog () {
  const [ sendDatalayerEvent ] = useDataLayer()

  const imageMap = {
    image01,
    image02,
    image03,
    image04,
  }

  return (
    <S.Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center'>
            <h2 className='fs-24 fs-lg-32 lh-30 fs-xl-40 lh-lg-40 lh-xl-50 text-white font-sora mb-4' style={{ fontWeight: 600 }}>
              Dicas pra aproveitar as melhores <span className='d-xl-block'>ofertas da Black Friday</span>
            </h2>
            <p className='fs-14 lh-17 fs-md-18 lh-md-22 text-white'><span className='fw-700'>A Orange Friday é a Black Friday do Inter</span> e, além de aproveitar as melhores ofertas do mercado, por aqui a gente também te ajuda a escolher os melhores produtos e a comprar de forma segura!</p>
          </div>
          {
              data.map((item: {link: string; img: string; title: string}) => (
                <div className='col-12 col-md-6 col-xl-3 ' key={item.title}>
                  <a
                    href={item.link}
                    className='d-flex flex-column align-items-center'
                    onClick={() => sendDatalayerEvent({
                      section: 'dobra_10',
                      element_action: 'click button',
                      element_name: item.title,
                      section_name: 'Dicas pra aproveitar as melhores ofertas da Black Friday',
                      redirect_url: item.link,
                     })}
                  >
                    <img src={imageMap[item.img]} width={250} height={141} alt='' />
                    <h3 className='fs-16 lh-24 font-sora text-white mt-2' style={{ fontWeight: 600, width: 250 }}>{item.title}</h3>
                  </a>
                </div>
              ))
            }
        </div>
      </div>
    </S.Section>
  )
}

export default Blog
