import React from 'react'

import * as S from './styles'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { images } from '../../assets/images/_index'

import data from './assets/data/data.json'
import IntercoIcons from 'src/components/IntercoIcons'

import { ImgWebp } from 'src/styles/imgWebp'

interface ICondition {
  icon: string;
  title: string;
  description: string;
}

const BestPaymentConditions = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const conditions: ICondition[] = data

  return (
    <S.Section>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 order-2 order-md-1 mb-md-4'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white mb-4 mb-md-3 text-md-center'>
              Aproveite as melhores condições de pagamento
            </h2>
            <p className='fw-400 fs-14 lh-17 fs-lg-18 lh-lg-22 fs-xl-18 lh-xl-22 text-white mb-4 pb-2 text-md-center d-none d-md-block'>
              Pague do seu jeito e não fique de fora das melhores ofertas da Orange Friday.
            </p>
          </div>
          <div className='col-12 col-md-6 order-1 mb-4 mb-md-0'>
            <ImgWebp src={images.bannerBetterPaymentConditions.link} alt={images.bannerBetterPaymentConditions.alt} breakPointsSize={{ sm: '320px', md: '360px', lg: '440px', xl: '456px' }} />
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 order-3'>
            <div>
              {conditions.map((condition: ICondition) => (
                <S.ConditionCard key={condition.icon} className='mb-4'>
                  <div className='d-flex mb-2'>
                    <IntercoIcons icon={condition.icon} size='MD' color='#FF7A00' />
                    <span className='fs-16 lh-20 fs-lg-18 lh-lg-22 fs-xl-24 lh-xl-30 fw-600 font-sora text-white d-block mb-0 ml-2'>{condition.title}</span>
                  </div>
                  <div>
                    <p className='fs-14 lh-17 fs-xl-18 lh-xl-22 fw-400 text-white mb-0'>{condition.description}</p>
                  </div>
                </S.ConditionCard>
              ))}
            </div>
            <div className='pt-3'>
              <a
                href='https://intergo.app/045c6064'
                target='blank'
                className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-700 text-none'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    section_name: 'Aproveite as melhores condições de pagamento',
                    element_action: 'click button',
                    element_name: 'Entrar pra lista VIP',
                    redirect_url: 'https://intergo.app/045c6064',
                  })
                }}
              >
                Aproveitar no Inter Shop
              </a>
            </div>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default BestPaymentConditions
