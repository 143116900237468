import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  padding: 40px 0;
  background-color: ${grayscale[500]};


  @media ${device.tablet} {
    padding: 40px 0;
  }

  @media ${device.desktopLG} {
    padding: 80px 0;
  }

  @media ${device.desktopXL} {
    padding: 96px 0;
  }

  img {
    border-radius: 8px;
  }

`
