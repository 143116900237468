import styled from 'styled-components'

import { grayscale, orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  padding: 40px 0;
  background-color: ${grayscale[500]};
  margin-top: -1px;
  background-image: none;

  @media ${device.tablet} {
    padding: 40px 0 10px 0;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/mulher-dobra2/image.webp');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    z-index: 1;
  }

  @media ${device.desktopLG} {
    padding: 40px 0;
    padding-top: 120px;
    background-size: 90%;

    .button-quero-ser-vip {
      max-width: 542px !important;
      height: 58px;
    }
  }

  @media ${device.desktopXL} {
    padding: 96px 0 40px 0;
    background-size: 60%;
  }

  @media ${device.desktopXXL} {
    background-size: 62%;
  }

  @media ${device.desktopXXXL} {
    background-size: 50%;
  }

  position: relative;

`

export const Card = styled.div`
  border-radius: 12px;
  background-color: ${orange[200]};
  padding: 35px 25px 10px 25px;
  width: 75%;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;

  @media ${device.tablet} {
    width: 210px;
    margin-bottom: 0px;
  }

  @media ${device.tablet} {
    position: relative;
    &.orange-box-surpresa {
      top: -42px;
      left: -17px;
      width: 170px;
      height: 108px;
      padding: 25px 15px 10px 15px;
    }

    &.cupons-de-desconto {
      top: -42px;
      left: -71px;
      width: 170px;
      height: 95px;
      padding: 8px 15px 0 15px;
    }

    &.pagamento-facilitado {
      top: -16px;
      left: 1px;
      width: 200px;
      height: 108px;
      padding: 22px 15px 10px 15px;
    }

    &.duo-gourmet {
      top: -20px;
      left: 18px;
      width: 170px;
      height: 92px;
      padding: 8px 15px 0 15px;
    }

    &.oferta-relampago {
      top: 4px;
      left: 24px;
      width: 170px;
      height: 106px;
      padding: 15px 15px 0 15px;
    }
  }

  @media ${device.desktopLG} {
    &.orange-box-surpresa {
      top: -42px;
      left: 37px;
      width: 196px;
      height: 124px;
      padding: 25px 20px 10px 20px;
    }

    &.cupons-de-desconto {
      top: -76px;
      left: -42px;
      width: 198px;
      height: 112px;
      padding: 18px 20px 0 20px;
    }

    &.pagamento-facilitado {
      top: -15px;
      left: 37px;
      width: 230px;
      height: 124px;
      padding: 22px 20px 10px 20px;
    }

    &.duo-gourmet {
      top: -52px;
      left: 110px;
      width: 198px;
      height: 112px;
      padding: 8px 20px 0 20px;
    }

    &.oferta-relampago {
      top: -4px;
      left: 11px;
      width: 198px;
      height: 124px;
      padding: 15px 20px 0 20px;
    }
  }

  @media ${device.desktopXL} {
    &.orange-box-surpresa {
      top: -42px;
      left: 37px;
      width: 238px;
      height: 150px;
      padding: 12px 25px 10px 25px;
    }

    &.cupons-de-desconto {
      top: -76px;
      left: -42px;
      width: 238px;
      height: 144px;
      padding: 8px 25px 0 25px;
    }

    &.pagamento-facilitado {
      top: -15px;
      left: 37px;
      width: 280px;
      height: 175px;
      padding: 22px 25px 10px 25px;
    }

    &.duo-gourmet {
      top: -52px;
      left: 224px;
      width: 238px;
      height: 155px;
      padding: 0 25px 0 25px;
    }

    &.oferta-relampago {
      top: -4px;
      left: 104px;
      width: 238px;
      height: 162px;
      padding: 0 25px 0 25px;
    }
  }

  @media ${device.desktopXL} {
    width: 210px;
    margin-bottom: 0px;
  }
`

export const MiniCard = styled.div`
  border-radius: 12px;
  background-color: ${orange[200]};
  padding: 12px;
  width: 70px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
  margin-top: -60px;
  margin-bottom: 5px;
  position: relative;

  @media ${device.tablet} {
    width: 56px;
    svg {
      width: 32px;
      height: 32px;
    }
    &.orange-box-surpresa {
      top: -4px;
    }

    &.cupons-de-desconto {
      left: 86px;
      top: 12px;
    }

    &.pagamento-facilitado {
      top: -2px;
    }

    &.duo-gourmet {
      left: 94px;
      top: 21px;
    }

    &.oferta-relampago {
      left: 82px;
      top: 15px;
    }
  }

  @media ${device.desktopLG} {
    width: 64px;
    svg {
      width: 36px;
      height: 36px;
    }
    &.orange-box-surpresa {
      top: -4px;
    }

    &.cupons-de-desconto {
      left: 115px;
      top: 12px;
    }

    &.pagamento-facilitado {
      top: -2px;
    }

    &.duo-gourmet {
      left: 100px;
      top: 21px;
    }

    &.oferta-relampago {
      left: 112px;
      top: 15px;
    }
  }

  @media ${device.desktopXL} {
    width: 76px;
    svg {
      width: 45px;
      height: 45px;
    }
    &.orange-box-surpresa {
      top: 0;
    }

    &.cupons-de-desconto {
      left: 115px;
      top: 12px;
    }

    &.pagamento-facilitado {
      top: -2px;
    }

    &.duo-gourmet {
      left: 115px;
      top: 21px;
    }

    &.oferta-relampago {
      left: 115px;
      top: 15px;
    }
  }
`

export const VipBg = styled.div`
  width: 100%;
  height: 482px;
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra-01-background/image.webp');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 0;

  @media ${device.tablet} {
    height: 482px;
  }

  @media ${device.desktopLG} {
    height: 512px;
  }

  @media ${device.desktopXL} {
    height: 640px;
  }
`
