import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import * as S from './styles'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import produtosJSON from './data.json'
import { ImgWebp } from 'src/styles/imgWebp'

type saveEvenMoreProps = {
  title: string;
  link: string;
  img: string;
  redirect: string;
}

const FlashSale = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <S.Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 font-sora lh-30 lh-lg-40 lh-lg-50 text-white' style={{ fontWeight: 600 }}>Tem oferta relâmpago pra se jogar na <span className='d-md-block'>Orange Friday!</span></h2>
            <p className='fs-18 lh-22 text-white'>Produtos incríveis até pela <span className='fw-600'>metade do preço</span>.</p>
          </div>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 20 }}
              md={{ items: 2, partialVisibilityGutter: 20 }}
              lg={{ items: 3, partialVisibilityGutter: 20 }}
              xl={{ items: 4, partialVisibilityGutter: 20 }}
              autoPlay
              infinite
              customColor='white'
            >
              {
                produtosJSON.map((item: saveEvenMoreProps) => (
                  <S.Card key={item.title}>
                    <S.CardText className='fs-18 lh-30 text-white mb-0'>{item.title}</S.CardText>
                    <S.CardImg>
                      <ImgWebp
                        src={item.img}
                        breakPointsSize={{ sm: '100%' }}
                      />
                    </S.CardImg>
                    <S.CardLabels>
                      <p className='discount mb-0 fs-14 fw-700 text-white'>
                        Até 50% OFF
                      </p>
                      <p className='stock mb-0 fs-14 fw-700 text-white'>
                        Estoque Limitado
                      </p>
                    </S.CardLabels>
                    <div className='ml-3 mt-3'>
                      <p className='text-white fs-14 mb-0' style={{ fontWeight: 700 }}>De: <S.CardPrice>R$ 8.057,00</S.CardPrice></p>
                      <p className='text-white fs-20 mb-0' style={{ fontWeight: 700 }}>Por: <S.CardPrice>R$ 6.846,89</S.CardPrice></p>
                    </div>
                    <div className='px-3 py-3'>
                      <a
                        href={item.link} target='__blank'
                        rel='noreferrer'
                        className='btn btn-orange--extra btn--lg rounded-2 text-none text-white '
                        onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_03',
                          section_name: 'Tem oferta relâmpago pra se jogar',
                          element_action: 'click banner',
                          element_name: 'Liberar ofertas + ' + item.title,
                          redirect_url: item.redirect,
                        })
                        }}
                      >Liberar ofertas
                      </a>
                    </div>
                  </S.Card>
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default FlashSale
